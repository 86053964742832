<template>
  <div class="box" @click="onClick">
    <div class="box1">
      <van-image :src="img" class="school-img" />
    </div>
    <div class="box2">
      <div class="line1">
        <span class="school-name">
          {{ title }}
        </span>
        <div class="province-box">
          <img src="@/assets/images/mt/position.png" class="province-img" />
          <span class="province-txt">{{ province }}</span>
        </div>
      </div>
      <div class="line2">
        <span class="teachtype">高等院校</span>
        <span class="verticalbar"></span>
        <span class="teachtype">{{ teachtype }}</span>
      </div>
      <div class="line3">
        <template v-for="item in characteristicGroup">
          <span class="characteristic">{{ item }}</span>
        </template>
        <div class="partition">{{ partition }}区</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolItem",
  components: {

  },
  props: {
    img: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '北京大学',
    },
    province: {
      type: String,
      default: '北京',
    },
    partition: {
      type: String,
      default: 'A',
    },
    teachtype: {
      type: String,
      default: '综合类',
    },
    characteristic: {
      type: String,
      default: '高等院校 综合类 A区',
    },
  },
  computed: {
    characteristicGroup: function () {
      var arr = this.characteristic.split(' ');
      arr.splice(0, 2);
      return arr;
    }
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.box1 {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .school-img {
    width: 50px;
    height: 50px;
  }
}

.box2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .school-name {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
  }
}

.box:hover {
  color: #FF6602;
}

.line1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  .province-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .province-txt {
      width: 50px;
      color: #888;
      font-size: 13px;
    }

    .province-img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;

  .teachtype {
    font-size: 13px;
    color: #555;
  }

  .verticalbar {
    border-left: 1px solid #c5c5c5;
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    margin: 0 10px;
    top: 2px;
  }
}

.line3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;

  .characteristic {
    font-size: 12px;
    color: gray;
    background-color: #F6F6F6;
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px 10px;
  }

  .partition {
    font-size: 12px;
    color: gray;
    background-color: #F6F6F6;
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px 10px;
  }
}
</style>
