<template>
  <div class="box">
    <div class="box1">
      <el-image :src="img" class="img-school" />
    </div>
    <div class="box2" @click="onClick">
      <div class="line1">
        <span class="title">
          {{ title }}
        </span>
        <img src="@/assets/images/pc/position.png" class="img-position" />
        <span class="province">{{ province }}</span>
      </div>
      <div class="line2">
        <span class="characteristic">{{ teachtype }}</span>
        <span class="verticalbar"></span>
        <template v-for="(item, index) in characteristicGroup">
          <span class="characteristic">{{ item }}</span>
          <span class="verticalbar"></span>
        </template>
        <span class="characteristic">{{ partition }}区</span>
      </div>
    </div>
    <div class="box3">
      <el-button type="primary" size="small" @click="onClick">查看详情</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolItem",
  components: {

  },
  props: {
    img: {
      type: String,
      default: '@/assets/images/pc/school.png',
    },
    title: {
      type: String,
      default: '北京大学',
    },
    province: {
      type: String,
      default: '北京',
    },
    partition: {
      type: String,
      default: 'A',
    },
    teachtype: {
      type: String,
      default: '综合类',
    },
    characteristic: {
      type: String,
      default: '高等院校 985 211',
    },
  },
  computed: {
    characteristicGroup: function () {
      return this.characteristic.split(' ');
    }
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box1 {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.box2:hover {
  color: #FF6602;
}

.box3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-school {
  width: 60px;
  height: 60px;
}

.img-position {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

.line1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.province {
  font-size: 14px;
  color: #888;
}

.characteristic {
  font-size: 14px;
  color: #555;
}

.verticalbar {
  border-left: 1px solid #c5c5c5;
  content: "";
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 0 10px;
  top: 2px;
}
</style>
